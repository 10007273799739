/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { dialog } from '@microsoft/teams-js';
import DropdownSection from '../../common/DropdownSection';
import TextInput from '../../common/TextInput';
import { ColourEnergyOrder, ColourEnergyValues } from '../../../global/types';
import InsightsFlowText from '../InsightsFlowText/InsightsFlowText';
import InsightsButton from '../../common/InsightsButton/InsightsButton';
import getPrivacyLink from '../../../util/privacyPolicyLink';
import { requestType } from '../../../config/constants';
import ColourEnergyBreakdown from '../../common/ColourEnergyBreakdown';
import CheckboxInsightsFlow from '../../CheckboxInsightsFlow';
import InsightsFlowTextNoActionButton from './InsightsFlowTextNoActionButton';

type ProfileRequestSent = (requestType: string) => void;

interface ColourEnergiesExistProps {
  emailAddress: string;
  displayName: string;
  // languageCode: string;
  colourEnergyValues: ColourEnergyValues;
  colourEnergyOrder: ColourEnergyOrder;
  hasNominatedPeople: boolean | null;
  postProfileRequest: ProfileRequestSent;
  createdAt: string,
  // pronoun: string,
  chapters: Array<{name: string}>,
  lng: string
}

interface ColourOrderWrongContentProps {
  titles: string[];
  onClick: ProfileRequestSent;
  btText: string;
}

function ColourOrderWrongContent({
  titles,
  btText,
  onClick,
}: ColourOrderWrongContentProps) {
  const sendRequest = () => {
    if (typeof onClick === 'function') {
      hotjar.event('colour-energy-order-is-wrong-email');
      onClick(requestType.WRONG_PROFILE);
    }
  };

  return (
    <div className="my-3 rounded rounded-sm">
      {titles.map((t) => (
        <p className="insightsFlowText">{t}</p>
      ))}
      <div className="col-12 col-sm-8">
        <InsightsButton
          title={btText}
          onClick={sendRequest}
          disabledFocusable={false}
        />
      </div>
    </div>
  );
}

/**
 * This screen shows the users colour energy
 * retrieved from the DB.
 */
function ColourEnergiesExist({
  emailAddress,
  displayName,
  createdAt,
  // pronoun,
  chapters,
  colourEnergyValues,
  colourEnergyOrder,
  // languageCode,
  postProfileRequest,
  hasNominatedPeople,
  lng,
}: ColourEnergiesExistProps) {
  const { t } = useTranslation('translation', { lng });

  const [checked, setChecked] = useState<boolean>(false);
  const [privacyUrl, setPrivacyUrl] = useState<string>('');

  useEffect(() => {
    const privacyPolicy = async () => {
      const url = await getPrivacyLink();
      setPrivacyUrl(url);
    };
    privacyPolicy();
  }, []);

  const onConfirmColourEnergies = async () => {
    hotjar.event('button-colour-energy-confirmed');
    // trigger call to bot
    dialog.url.submit('<confirmed>');
  };

  return (
    <div className="d-flex flex-column">
      <h3 className="mb-3">
        {t('ms-teams-insights-flow-colour-energies-exist-title')}
      </h3>
      <p className="mb-3 insightsFlowText">
        {t('ms-teams-insights-flow-colour-energies-exist-subtext')}
      </p>
      <p className="mb-2" style={{ fontWeight: 600, fontStyle: 'normal' }}>
        {t('ms-teams-insights-flow-colour-energies-exist-email-title')}
      </p>
      <TextInput readOnly value={emailAddress} />
      <ColourEnergyBreakdown
        displayName={displayName}
        profileDate={createdAt}
        // languageCode={languageCode}
        // pronoun={pronoun}
        chapters={(chapters || []).map((item) => item.name)}
        fullProfile
        colourEnergyValues={colourEnergyValues}
        colourEnergyOrder={colourEnergyOrder}
        lng={lng}
      />
      <DropdownSection
        title={t(
          'ms-teams-insights-flow-colour-energies-exist-drop-section-title',
        )}
        borderAbove
        textContent={
          hasNominatedPeople ? (
            <ColourOrderWrongContent
              titles={[
                t(
                  'ms-teams-insights-flow-colour-energies-exist-drop-section-body-1',
                ),
                t(
                  'ms-teams-insights-flow-colour-energies-exist-drop-section-body-2',
                ),
              ]}
              btText={t(
                'ms-teams-insights-flow-colour-energies-exist-request-button',
              )}
              onClick={() => postProfileRequest(
                t(
                  'ms-teams-insights-flow-support-request-sub-heading-colour-order-is-wrong',
                ),
              )}
            />
          ) : (
            <InsightsFlowTextNoActionButton
              text={[
                t(
                  'ms-teams-insights-flow-colour-energies-exist-drop-section-body-1',
                ),
                t(
                  'ms-teams-insights-flow-colour-energies-exist-drop-section-body-no-representative-2',
                ),
              ]}
            />
          )
        }
      />
      <InsightsFlowText
        text={t('ms-teams-insights-flow-colour-energies-exist-help-section')}
      />
      <CheckboxInsightsFlow
        onChange={() => setChecked(!checked)}
        checked={checked}
        privacyUrl={privacyUrl}
        lng={lng}
      />
      <div className="d-flex justify-content-end pt-4">
        <InsightsButton
          disabledFocusable={!checked}
          title={t(
            'ms-teams-insights-flow-colour-energies-exist-confirmation-button',
          )}
          onClick={onConfirmColourEnergies}
        />
      </div>
    </div>
  );
}

export default ColourEnergiesExist;
