/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { app, dialog } from '@microsoft/teams-js';
import MeetingSection from '../components/MeetingSection/MeetingSection';
import PersonalProfile from '../components/PersonalProfile/PersonalProfile';
import InsightsForWeek from '../components/InsightsForWeek/InsightsForWeek';
import {
  getMeetings,
  postBotInsightsMessage,
  postConfirmColourEnergies,
  postFeedbackBotMessage,
  getLearnerRecord,
  putLearnerProfileDate,
  postResetStatementBotMessage,
} from '../services/learnersService';
import UserPlaceholder from '../components/placeholders/UserPlaceholder';
import { UserContext } from '../context/user-context';
import MeetingPlaceholder from '../components/placeholders/MeetingPlaceholder';
import InfoSection from '../components/InfoSection';

/**
 * The main dashboard display in the Personal Tab
 * area of MS Teams. Contains the users profile,
 * summary of frequently interacted team members and
 * meetings.
 * @param {Object} data (From MS Graph API)
 * @returns
 */
function Dashboard() {
  const context = useContext(UserContext) || { themeState: 'default' };
  const { t } = useTranslation();

  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingMeetings, setIsLoadingMeetings] = useState(true);
  const [meetings, setMeetings] = useState<any>({});

  const fetchSignedInUser = async () => {
    setIsLoadingUser(true);
    if (context && context?.updateUser) {
      await context?.updateUser();
      const teamsAppData = await app.getContext();
      if (
        teamsAppData.user?.userPrincipalName
        && teamsAppData.user?.tenant?.id
      ) {
        postFeedbackBotMessage(
          teamsAppData.user?.userPrincipalName,
          teamsAppData.user?.tenant?.id,
        );
      }
    }
    setIsLoadingUser(false);
  };

  const fetchMeetings = async () => {
    setIsLoadingMeetings(true);

    const meetingsResult = await getMeetings();

    if (meetingsResult.status === 200) {
      setMeetings({ attendees: meetingsResult.data, error: null });
    } else {
      setMeetings({ error: meetingsResult.status });
    }

    setIsLoadingMeetings(false);
  };

  const refreshDashboard = () => {
    fetchSignedInUser();
    fetchMeetings();
  };

  const onModalSubmit = async (evt: any) => {
    const { result } = evt;

    if (result === '<confirmed>') {
      const teamsAppData = await app.getContext();
      if (
        teamsAppData.user?.userPrincipalName
        && teamsAppData.user?.tenant?.id
      ) {
        await postConfirmColourEnergies(
          teamsAppData.user?.userPrincipalName,
          teamsAppData.user?.tenant?.id,
        );
        // Do not wait for the bot response to render the dashboard
        postBotInsightsMessage(
          teamsAppData.user?.userPrincipalName,
          teamsAppData.user?.tenant?.id,
        );
      }

      refreshDashboard();
    }
  };

  const handleModal = async (lng: string) => {
    const dialogInfo = {
      title: t('ms-teams-insights-flow-tab-title'),
      url: `${window.location.origin}/insights?lng=${lng}`,
      size: {
        width: 1200,
        height: 900,
      },
    };
    dialog.url.open(dialogInfo, onModalSubmit);
  };

  /**
   * Used to check if the user has come via a deep link in the welcome
   * card (if so subPageId will be set to an arbituary value).
   */
  const getSubPageId = async () => {
    const teamsAppData = await app.getContext();

    const userEmail = teamsAppData.user?.userPrincipalName;

    if (
      // Only trigger if they have not setup colour energy.
      teamsAppData
      && teamsAppData.page
      && teamsAppData.page.subPageId
      && userEmail
    ) {
      const { data } = (await getLearnerRecord(userEmail)) as any;

      const confirmedDate = data.colour_energies_confirmed_at;

      if (!confirmedDate) {
        await handleModal(teamsAppData.app.locale || 'en-GB');
      }
    }
  };

  const handleStatementReset = async (createdAtProfileDate: string) => {
    const teamsAppData = await app.getContext();
    const userEmail = teamsAppData.user?.userPrincipalName;

    if (userEmail && createdAtProfileDate) {
      const result = await putLearnerProfileDate(
        createdAtProfileDate,
        userEmail,
      ); // the date for the profile creation from Insights API.

      if (result.status === 200) {
        if (
          teamsAppData.user?.userPrincipalName
          && teamsAppData.user?.tenant?.id
        ) {
          await postResetStatementBotMessage(
            // send bot message
            teamsAppData.user?.userPrincipalName,
            teamsAppData.user?.tenant?.id,
          );
        }
      }
    }
  };

  useEffect(() => {
    hotjar.stateChange('/personal-dashboard');
  }, []);

  useEffect(() => {
    getSubPageId();
    // TODO - add the handler for the profile date here.
  }, []);

  // Fetch the User info
  useEffect(() => {
    fetchSignedInUser();
  }, []);

  useEffect(() => {
    fetchMeetings();
  }, []);

  // Fetch the User info
  useEffect(() => {
    if (context.user && context.user.id) {
      hotjar.identify(context.user.id, {
        organisationName: context.user.organisationName,
      });
      handleStatementReset(context.user.createdAt);
    }
  }, [context.user && context.user.id]);

  document.body.className = `bg_${context.themeState}_main`;

  if (context.themeState === 'loading') {
    return null;
  }

  return (
    <>
      {context && context?.user ? (
        <div
          className={`card_padding bg_${context.themeState}_main`}
          style={{
            // backgroundColor: '#f5f5f5',
            height:
              context
              && context?.user
              && context?.user.colourEnergiesConfirmedAt
                ? 'auto'
                : 'auto',
          }}
        >
          {!isLoadingUser && context && context?.user ? (
            <div
              className={`d-flex flex-lg-row flex-column dashboard-container_${
                context.themeState
              } ${
                context.user?.colourEnergiesConfirmedAt
                  ? `personal_profile_container_bg_${context.themeState}`
                  : ''
              }`}
              style={{ borderRadius: 4 }}
            >
              <div
                className={`${
                  context.user?.colourEnergiesConfirmedAt
                    ? 'col-lg-6'
                    : 'col-md-12 col-lg-12'
                } col-xs-12 dashboard-container_personal_profile_${
                  context.themeState
                }`}
              >
                <PersonalProfile
                  user={context.user}
                  profileViews={context.user?.profileViews}
                  colourEnergiesConfirmedAt={
                    context.user?.colourEnergiesConfirmedAt
                  }
                  createdAt={context.user?.createdAt}
                  displayName={context.user?.displayName}
                  email={context.user?.email}
                  consciousColourEnergyValues={
                    context.user.consciousColourEnergyValues
                  }
                  colourEnergyOrder={context.user.consciousColourEnergy}
                  fullProfile={context.user.fullProfile}
                  theme={context.themeState}
                  error={context.user.error}
                  onSuccessConsent={fetchSignedInUser}
                  onRefresh={refreshDashboard}
                />
              </div>
              {context.user?.colourEnergiesConfirmedAt ? (
                <div
                  className={`col-lg-6 col-12 pt-4 border-left_${context.themeState} insights_weekly_container_${context.themeState}`}
                >
                  <InsightsForWeek
                    theme={context.themeState}
                    data={context.user.insightsForThisWeek}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <UserPlaceholder
              theme={context.themeState}
              text={t('ms-teams-fetching-profile-content')}
            />
          )}
          {/* Render people and meetings tiles only when user confirms the colour energies */}
          {context
          && context?.user
          && context?.user.colourEnergiesConfirmedAt ? (
            <>
              {!isLoadingMeetings ? (
                <MeetingSection
                  title={t('ms-teams-meeting-section-title')}
                  subtext={t('ms-teams-meeting-section-subtext')}
                  meetings={meetings.attendees}
                  theme={context.themeState}
                  error={meetings.error}
                  onSuccessConsent={async () => {
                    await fetchMeetings();
                    window.location.reload();
                  }}
                />
              ) : (
                <MeetingPlaceholder
                  theme={context.themeState}
                  text={t('ms-teams-checking-teams-data')}
                />
              )}
              <p
                className={`text_colour_${context.themeState} noProfileSetInfoSectionTitle paddingLeft20`}
                id="Info-Title-FAQs"
              >
                {t('ms-teams-info-title')}
              </p>
              <InfoSection
                theme={context.themeState}
                userEmail={context.user?.email}
                isProfileSet
              />
            </>
            ) : null}
        </div>
      ) : null}
    </>
  );
}

export default Dashboard;
