/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Avatar } from '@fluentui/react-components';
import { app, dialog } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  getSignedInUserPhoto,
  postBotInsightsMessage,
  postConfirmColourEnergies,
} from '../../services/learnersService';
import {
  ColourEnergyOrder,
  ColourEnergyValues,
  StatementEnhanced,
} from '../../global/types';
import Error from '../common/Error/Error';
import NoSetupProfile from './PersonalProfileSetup';
import { ReactComponent as SetupLogo } from '../../images/SetupLogo.svg';
import { ReactComponent as SetupLogoDark } from '../../images/SetupLogoDark.svg';
import EnergySplit from '../common/EnergySplit';
import Highlights from '../common/Highlights';
import StrengthsWeaknesses from '../common/StrengthsWeaknesses';
import CommsDoDont from '../common/CommsDoDont';
import InfoSection from '../InfoSection';

interface PersonalProfileProps {
  user: any;
  profileViews: number;
  displayName: string;
  consciousColourEnergyValues: ColourEnergyValues;
  colourEnergyOrder: ColourEnergyOrder;
  fullProfile: boolean;
  email: string;
  colourEnergiesConfirmedAt: string;
  createdAt: string;
  theme: string;
  error: number | null;
  onSuccessConsent(): void;
  onRefresh(): any;
}

/**
 * Personal profile shows the users MS teams profile picture,
 * the display name and the associated colour energies.
 */
function PersonalProfile({
  user,
  profileViews,
  displayName,
  consciousColourEnergyValues,
  colourEnergyOrder,
  fullProfile,
  email,
  colourEnergiesConfirmedAt,
  createdAt,
  theme,
  error,
  onSuccessConsent,
  onRefresh,
}: PersonalProfileProps) {
  const { t } = useTranslation();

  const [photo, setPhoto] = useState<string>('');

  // Fetch the User info
  useEffect(() => {
    const fetchUSerPhoto = async () => {
      const photoResult = await getSignedInUserPhoto();

      if (photoResult.status === 200) {
        setPhoto(photoResult.data as string);
      }
    };
    fetchUSerPhoto();
  }, []);

  type ModalSubmitEvt = { [key: string]: '<close>' | '<confirmed>' };

  const onModalSubmit = async (evt: unknown) => {
    const teamsAppData = await app.getContext();
    const { result } = evt as ModalSubmitEvt;
    if (result === '<confirmed>' && teamsAppData.user?.tenant?.id) {
      const userEmail = teamsAppData.user?.userPrincipalName || email;
      await postConfirmColourEnergies(
        userEmail as string,
        teamsAppData.user?.tenant?.id,
      );
      // Do not wait for the bot response to render the dashboard
      if (userEmail && teamsAppData.user?.tenant.id) {
        postBotInsightsMessage(userEmail, teamsAppData.user?.tenant.id);
      }

      await onRefresh();
    }
  };

  const handleModal = async () => {
    const teamsAppData = await app.getContext();
    const lng = teamsAppData.app.locale;
    const dialogInfo = {
      title: t('ms-teams-insights-flow-tab-title'),
      url: `${window.location.origin}/insights?lng=${lng}`,
      size: {
        width: 1200,
        height: 900,
      },
    };
    dialog.url.open(dialogInfo, onModalSubmit);
  };

  const handleSettingsModal = () => {
    const dialogInfo = {
      title: t('ms-teams-customise-statements-title'),
      url: `${window.location.origin}/customise-statements`,
      size: {
        width: 600,
        height: 620,
      },
    };

    // Passing the onSuccessConsent function to update user dashboard if settings change.
    dialog.url.open(dialogInfo, onSuccessConsent);
  };

  const scrollToTop = (id: string) => {
    const elements = document.getElementById(id);
    if (elements) {
      elements.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'start',
      });
    }
  };

  const filterHiddenStatements = (statements: StatementEnhanced[]) => statements.filter((statement) => statement.personal === true);

  return (
    <div className="flex-column w-100" id="profileSummary">
      {error ? (
        <Error
          error={error}
          consentScope="User.Read"
          successCallback={onSuccessConsent}
          theme={theme}
        />
      ) : colourEnergiesConfirmedAt ? (
        <div className={`bg_${theme}_card summary_section_shadow`}>
          {/* Avatar, profile summary title, profile view count & energy split */}
          <div
            className={`d-flex flex-column card_padding bg_${theme}_secondary`}
            style={{
              paddingBottom: 0,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <div className="d-flex flex-md-row flex-column align-items-center align-items-md-start justify-content-center mb-md-3 mb-1">
              <Avatar
                image={{ src: photo }}
                name={displayName}
                style={{ width: '80px', height: '80px' }}
                className="ms-0 ms-sm-3 ms-md-0 mb-2 mb-md-0"
              />
              <div
                className="ms-sm-3 ms-auto d-flex w-100 flex-column justify-content-md-center align-items-md-start justify-content-center align-items-center"
                style={{ minHeight: '80px' }}
              >
                <h3
                  className={`personal_profile_title avatarTitle_${theme}`}
                  style={{ margin: 0, marginBottom: 4 }}
                >
                  {t('ms-teams-personal-profile-your-summary')}
                </h3>
                <div className="d-flex flex-sm-row flex-column">
                  <p
                    className={`avatarParagraph_${theme} personal_profile_subtext m-0`}
                  >
                    {t('ms-teams-personal-dashboard-colours-confirmed-title', {
                      confirmedAt: moment.utc(createdAt).format('DD MMM YYYY'),
                    })}
                    {' '}
                    •
                    {' '}
                  </p>
                  <p
                    className={`personal_profile_viewed avatarTitle_${theme} m-0`}
                    style={{ marginLeft: 2 }}
                  >
                    {t('ms-teams-personal-profile-viewed')}
                    {' '}
                    <span style={{ fontWeight: 600 }}>
                      <span id="profileViewsCount">{profileViews}</span>
                      {` ${t('ms-teams-personal-profile-times')}`}
                    </span>
                    {' '}
                    {t('ms-teams-personal-profile-this-week')}
                  </p>
                </div>
              </div>
            </div>
            <EnergySplit
              theme={theme}
              colourEnergyValues={consciousColourEnergyValues}
              colourEnergyOrder={colourEnergyOrder}
              fullProfile={fullProfile}
            />
          </div>
          <table
            className={`jump-to-table personal_profile_info_bg_${theme}`}
            style={{ borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }}
          >
            <thead>
              <tr>
                <th className="jump-to-table-header p-0 m-0">
                  <div
                    className={`jumpToContainer jumpToContainer_${theme} personal_profile_shadow justify-content-sm-center justify-content-start align-items-sm-center align-items-start p-sm-0 p-3`}
                  >
                    <p
                      className={`section_title avatarParagraph_${theme}`}
                      style={{ marginBottom: 0, marginRight: 10 }}
                    >
                      {t('ms-teams-personal-profile-jump-to')}
                    </p>
                    <div className="d-flex flex-sm-row flex-column justify-content-end">
                      <a
                        role="presentation"
                        className={`jumpToLinks avatarLink_${theme}`}
                        onClick={() => scrollToTop('personal_profile_highlights')}
                        id="highlightScrollLink"
                      >
                        {t('ms-teams-personal-profile-highlights')}
                      </a>
                      <a
                        role="presentation"
                        className={`jumpToLinks avatarLink_${theme}`}
                        onClick={() => scrollToTop('personal_profile_strengths_weaknesses')}
                        id="strengthWeaknessLink"
                      >
                        {t('ms-teams-personal-profile-strengths-weaknessees')}
                      </a>
                      <a
                        role="presentation"
                        className={`jumpToLinks avatarLink_${theme}`}
                        onClick={() => scrollToTop('personal_profile_comms')}
                        id="doDontScrollLink"
                      >
                        {t('ms-teams-personal-profile-dos-donts')}
                      </a>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <div
                  className="d-flex flex-column card_padding tableContentHeight pt-3"
                  style={{ overflowY: 'scroll', paddingTop: 0 }}
                >
                  <div className="d-flex flex-column flex-md-row">
                    <p
                      className={`avatarParagraph_${theme} avatarLink`}
                      style={{ margin: 0, width: '80%', marginRight: 10 }}
                    >
                      {t('ms-teams-profile-customise-profile-description')}
                    </p>
                    <button
                      id="customiseProfileBtn"
                      type="button"
                      className={`teamsButton teamsButtonSecondary${theme} mt-md-0 mt-2 `}
                      style={{
                        width: 'fit-content',
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: 32,
                        marginLeft: 16,
                        margin: 0,
                        whiteSpace: 'nowrap',
                      }}
                      onClick={handleSettingsModal}
                    >
                      {t('ms-teams-customise-profile')}
                    </button>
                  </div>
                  <div
                    className={`personal_profile_spacer personal_profile_spacer_${theme}`}
                  />
                  <Highlights
                    id="personal_profile_highlights"
                    theme={theme}
                    values={filterHiddenStatements(user.highlights) || []}
                  />
                  <StrengthsWeaknesses
                    id="personal_profile_strengths_weaknesses"
                    theme={theme}
                    strengths={filterHiddenStatements(user.strengths) || []}
                    weaknesses={filterHiddenStatements(user.weakness) || []}
                  />
                  <CommsDoDont
                    id="personal_profile_comms"
                    theme={theme}
                    dos={filterHiddenStatements(user.communicationDos) || []}
                    donts={
                      filterHiddenStatements(user.communicationDonts) || []
                    }
                  />
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <NoSetupProfile
            theme={theme}
            photo={photo}
            handleModal={handleModal}
            displayName={displayName}
          />
          <div className="col-lg-6 col-12 noProfileSetInfoSection">
            <p
              className={`text_colour_${theme} noProfileSetInfoSectionTitle`}
              id="faqTitle"
            >
              {t('ms-teams-insights-no-colour-energy-profile-info-main-title')}
            </p>
            <InfoSection theme={theme} userEmail={email} isProfileSet={false} />
            <div className="noProfileInfoSetSpacer" />
            <div className="col-10 noProfileSetInfoSectionFooter">
              <h6
                id="footerTitle"
                className={`${theme}_sub_title`}
                style={{ marginBottom: 7 }}
              >
                {t(
                  'ms-teams-insights-no-colour-energy-profile-footer-powered-by',
                )}
              </h6>
              {theme === 'default' ? <SetupLogo /> : <SetupLogoDark />}
              <p
                className={`noProfileSetInfoSectionTitle text_colour_${theme}`}
                style={{ marginTop: 20 }}
              >
                {t('ms-teams-insights-no-colour-energy-profile-footer-title')}
              </p>
              <p className={`${theme}_sub_title`}>
                {t(
                  'ms-teams-insights-no-colour-energy-profile-footer-description',
                )}
              </p>
              <a
                id="footerLinkBtn"
                href="https://www.insights.com/"
                target="_blank"
                className={`secondaryButton_${theme} sub_${theme}_title mb-3 api_content_heading_default`}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                }}
                rel="noreferrer"
              >
                {t('ms-teams-insights-no-colour-energy-profile-footer-button')}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PersonalProfile;
