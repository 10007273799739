/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import { app, dialog } from '@microsoft/teams-js';
import { Label } from '@fluentui/react-components';
import Checkbox from './common/Checkbox';
import Radio from './common/Radio';
import Select from './common/Select';
import { UserContext } from '../context/user-context';
import Error from './common/Error/Error';
import {
  postAddNominatedPerson,
  postAllowShareColourEnergies,
  postUpdateUserSettings,
  deleteUserRequest,
  getSignedInUser,
} from '../services/learnersService';
import CustomToggle from './common/Toggle';
import TeamsButton from './common/TeamsButton';
import { getErrorTextColour } from '../util/theme';
import Spinner from './common/Spinners/Spinner';

const BACK_DOOR_DELETE_USER = [
  'plourenco@vidatec.com',
  'pedro@ildm365.onmicrosoft.com',
  'PNobre@ildm365.onmicrosoft.com',
  'Plamena@ildm365.onmicrosoft.com',
  'ihoey@ildm365.onmicrosoft.com',
  'dknight@ildm365.onmicrosoft.com',
  'Barry@ildm365.onmicrosoft.com',
  'sclark@insights.com',
];

/**
 * This component is used to display the required
 * Settings statement which can be found in a link in the
 * about tab.
 */
function Settings() {
  const { t } = useTranslation();
  const context = useContext(UserContext) || { themeState: 'default' };
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [savingNominatedPerson, setNominatedPerson] = useState(false);
  const [saving, setIsSaving] = useState(false);
  const [settings, setSettings] = useState<any>(null);

  const [allowShare, setAllowShare] = useState<string>('1');

  const onChangeRadio = (key: string) => {
    const curSelection = settings.important;
    const resetState = Object.keys(curSelection).reduce(
      (prev, v) => ({ ...prev, [v]: false }),
      {},
    );
    const newState = { ...settings, important: { ...resetState, [key]: true } };
    setSettings(newState);
  };

  const onChangeOption = (settingKey: string, key: string) => {
    const curSelection = settings[settingKey];
    const prevValue = settings[settingKey][key];
    const newState = {
      ...settings,
      [settingKey]: { ...curSelection, [key]: !prevValue },
    };
    setSettings(newState);
  };

  const onChangeSelect = (value: string) => {
    const newState = { ...settings, length: { value } };
    setSettings(newState);
  };

  const values = [
    {
      title: t('ms-teams-settings-important-meeting-length-option0'),
      value: '0',
    },
    {
      title: t('ms-teams-settings-important-meeting-length-option1'),
      value: '30',
    },
    {
      title: t('ms-teams-settings-important-meeting-length-option2'),
      value: '60',
    },
    {
      title: t('ms-teams-settings-important-meeting-length-option3'),
      value: '120',
    },
    {
      title: t('ms-teams-settings-important-meeting-length-option4'),
      value: '180',
    },
    {
      title: t('ms-teams-settings-important-meeting-length-option5'),
      value: '240',
    },
    {
      title: t('ms-teams-settings-important-meeting-length-option6'),
      value: '300',
    },
  ];

  const fetchSignedInUser = async () => {
    setIsLoadingUser(true);
    if (context && context?.updateUser) {
      await context?.updateUser();
    }
    setIsLoadingUser(false);
  };

  // Fetch the User info
  useEffect(() => {
    hotjar.stateChange('/settings');
    fetchSignedInUser();
  }, []);

  useEffect(() => {
    if (!isLoadingUser) {
      const settingContext = context.user?.settings;
      setSettings(settingContext);
    }
  }, [isLoadingUser]);

  const saveSettings = async () => {
    hotjar.event('settings-saved');
    setIsSaving(true);
    const teamsAppData = await app.getContext();

    let signedInUserEmail = context?.user?.email || teamsAppData.user?.userPrincipalName || '';
    const tenantId = teamsAppData.user?.tenant?.id;

    if (!signedInUserEmail && tenantId) {
      // backup - check graph for current user if all others fail.
      const { data } = await getSignedInUser(tenantId);
      signedInUserEmail = data?.userPrincipalName;
    }

    if (signedInUserEmail) {
      await postUpdateUserSettings(signedInUserEmail, settings);
      await postAllowShareColourEnergies(signedInUserEmail, {
        allowShareColourEnergies: +allowShare,
      });

      if (tenantId && context.user?.isNominated !== savingNominatedPerson) {
        await postAddNominatedPerson(signedInUserEmail, tenantId);
      }
    }
    setIsSaving(false);

    fetchSignedInUser();
  };

  const deleteUser = async () => {
    const email = context?.user?.email;
    if (email) {
      await deleteUserRequest(email);
    }
  };

  const nominateUser = () => setNominatedPerson(!savingNominatedPerson);

  const handleModal = () => {
    // const email = context?.user?.email;
    // if (email) {
    const dialogInfo = {
      title: t('ms-teams-customise-statements-title'),
      url: `${window.location.origin}/customise-statements`,
      size: {
        width: 600,
        height: 620,
      },
    };
    dialog.url.open(dialogInfo);
    // }
  };

  if (!isLoadingUser && !context.user?.colourEnergiesConfirmedAt) {
    return (
      <div
        className={`bg_${context.themeState}_main`}
        style={{ height: '100vh', minHeight: '100%' }}
      >
        <div className="d-flex flex-column p-3 pt-5 text-center">
          <Label
            id="noProfileTitle"
            className="pb-2"
            style={{ color: getErrorTextColour(context.themeState) }}
            size="large"
          >
            {' '}
            {t('ms-teams-avatar-no-colours-title')}
          </Label>
          <Label
            style={{ color: getErrorTextColour(context.themeState) }}
            id="noProfileDescription"
          >
            {t('ms-teams-avatar-no-colours-description')}
          </Label>
          <Label
            className="mt-2"
            id="noProfileDescription2"
            style={{ color: getErrorTextColour(context.themeState) }}
          >
            {t('ms-teams-avatar-no-colours-description2')}
          </Label>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg_${context.themeState}_main settings_container col-12 scroll_${context.themeState}`}
      style={{ maxHeight: 280, overflowY: 'auto' }}
    >
      {isLoadingUser ? (
        <Spinner theme={context.themeState} animationName="spin" />
      ) : null}

      {!isLoadingUser && context && context?.user && settings ? ( //
        context.user.error ? (
          <Error
            error={context?.user.error}
            consentScope="User.Read"
            successCallback={fetchSignedInUser}
            theme={context.themeState}
          />
        ) : (
          <div>
            {/* Set colour energy display */}
            <div className="settings_section">
              <h4
                id="settingsTitle"
                className={`settings_section_title text_colour_${context.themeState}`}
              >
                {t('ms-teams-settings-important-meeting-hide-show-title')}
              </h4>
              <Radio
                theme={context.themeState}
                label={t(
                  'ms-teams-personal-dashboard-colours-confirmed-dropdown-opt-in',
                )}
                defaultValue={context.user.allowShareColourEnergies}
                onClick={() => {
                  setAllowShare('1');
                }}
                name="colourEnergyGroup"
                id="show"
              />
              <Radio
                theme={context.themeState}
                label={t(
                  'ms-teams-personal-dashboard-colours-confirmed-dropdown-opt-out',
                )}
                defaultValue={!context.user.allowShareColourEnergies}
                onClick={() => {
                  setAllowShare('0');
                }}
                name="colourEnergyGroup"
                id="hide"
              />
            </div>
            <div>
              <p
                style={{ marginBottom: 0 }}
                className={`avatarParagraph_${context.themeState} avatarLink`}
              >
                {t('ms-teams-settings-customise-profile')}
              </p>
              <button
                type="button"
                className={`teamsButton teamsButtonSecondary${context.themeState}`}
                style={{
                  width: 'auto',
                  marginBottom: 20,
                  marginTop: 12,
                }}
                onClick={handleModal}
                id="SettingsModalBtn"
              >
                {t('ms-teams-customise-profile')}
              </button>
            </div>
            <div className="settings_section">
              <h4
                className={`settings_section_title text_colour_${context.themeState}`}
              >
                {t('ms-teams-settings-important-meeting-title')}
              </h4>
              <h4
                className={`sub_${context.themeState}_title avatarParagraph_${context.themeState}`}
              >
                {t('ms-teams-settings-important-meeting-sub-heading')}
              </h4>
              <Checkbox
                theme={context.themeState}
                label={t('ms-teams-settings-important-meeting-one-to-one')}
                onClick={() => {
                  onChangeOption('meetingSize', 'one2one');
                }}
                value={settings.meetingSize.one2one}
                name="colourEnergyGroup"
                id="one2one"
              />
              <Checkbox
                theme={context.themeState}
                label={t('ms-teams-settings-important-meeting-small')}
                value={settings.meetingSize.small}
                onClick={() => {
                  onChangeOption('meetingSize', 'small');
                }}
                name="colourEnergyGroup"
                id="small"
              />
              <Checkbox
                theme={context.themeState}
                label={t('ms-teams-settings-important-meeting-bigger')}
                value={settings.meetingSize.big}
                onClick={() => {
                  onChangeOption('meetingSize', 'big');
                }}
                name="colourEnergyGroup"
                id="big"
              />
              <Checkbox
                theme={context.themeState}
                label={t('ms-teams-settings-important-meeting-larger')}
                value={settings.meetingSize.large}
                onClick={() => {
                  onChangeOption('meetingSize', 'large');
                }}
                name="colourEnergyGroup"
                id="large"
              />
            </div>
            {/* <div className="settings_section">
          <h4 className="sub_title">
            {t('ms-teams-settings-important-meeting-recurrence-title')}
          </h4>
          <Checkbox
            label={t('ms-teams-settings-important-meeting-recurrence-daily')}
            value={settings.recurrence.daily}
            onClick={() => onChangeOption('recurrence', 'daily')}
            name="colourEnergyGroup"
            id="hide"
          />
          <Checkbox
            label={t('ms-teams-settings-important-meeting-recurrence-weekly')}
            value={settings.recurrence.weekly}
            onClick={() => onChangeOption('recurrence', 'weekly')}
            name="colourEnergyGroup"
            id="hide"
          />
          <Checkbox
            label={t('ms-teams-settings-important-meeting-recurrence-bi-weekly')}
            value={settings.recurrence.biweekly}
            onClick={() => onChangeOption('recurrence', 'biweekly')}
            name="colourEnergyGroup"
            id="hide"
          />
          <Checkbox
            label={t('ms-teams-settings-important-meeting-recurrence-monthly')}
            value={settings.recurrence.monthly}
            onClick={() => onChangeOption('recurrence', 'monthly')}
            name="colourEnergyGroup"
            id="hide"
          />
        </div> */}
            <div className="settings_section col-md-3  col-12">
              <h4
                className={`sub_${context.themeState}_title text_colour_${context.themeState}`}
              >
                {t('ms-teams-settings-important-meeting-length-title')}
              </h4>
              <Select
                theme={context.themeState}
                values={values}
                onClick={onChangeSelect}
                defaultValue={settings.length.value}
                id="meetingLengthSelector"
              />
            </div>
            <div className="settings_section">
              <h4
                className={`settings_section_title text_colour_${context.themeState}`}
              >
                {t('ms-teams-settings-important-meeting-location-title')}
              </h4>
              <Radio
                theme={context.themeState}
                label={t('ms-teams-settings-important-meeting-location-all')}
                defaultValue={settings.important.all}
                onClick={onChangeRadio}
                name="locationGroup"
                id="all"
              />
              <Radio
                theme={context.themeState}
                label={t(
                  'ms-teams-settings-important-meeting-location-defined',
                )}
                defaultValue={settings.important.defined}
                name="locationGroup"
                onClick={onChangeRadio}
                id="defined"
              />
              <Radio
                theme={context.themeState}
                label={t('ms-teams-settings-important-meeting-location-remote')}
                defaultValue={settings.important.remote}
                name="locationGroup"
                onClick={onChangeRadio}
                id="remote"
              />
            </div>
            {/* Opt in notification section */}
            <div className="settings_section">
              <h4
                className={`settings_section_title text_colour_${context.themeState}`}
              >
                {t('ms-teams-settings-notification-title')}
              </h4>
              <p
                className={`settings_notification_subtext text_colour_${context.themeState}`}
                style={{ marginBottom: 10 }}
              >
                {t('ms-teams-settings-notification-subtext')}
              </p>
              <CustomToggle
                value={context.user?.isNominated}
                onClick={nominateUser}
                theme={context.themeState}
              />
              <p
                className={`settings_notification_disclaimer text_colour_${context.themeState}`}
              >
                {t('ms-teams-settings-notification-disclaimer')}
              </p>
            </div>
            <button
              id="saveSettingsBtn"
              type="button"
              className={`teamsButton teamsButton${context.themeState}`}
              style={{
                cursor: 'pointer',
                width: 'fit-content',
                whiteSpace: 'nowrap',
              }}
              onClick={saveSettings}
            >
              {!saving
                ? t('ms-teams-settings-save-button-text')
                : t('ms-teams-saving')}
            </button>
            {BACK_DOOR_DELETE_USER.includes(context?.user?.email) ? (
              <div
                className="pt-2 pb-2 col-3 faq_button"
                style={{
                  margin: 0,
                  marginTop: 15,
                }}
              >
                <TeamsButton
                  theme={context.themeState}
                  onClick={deleteUser}
                  btnText="Delete User"
                />
              </div>
            ) : null}
          </div>
        )
      ) : null}
    </div>
  );
}

export default Settings;
