/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { pages } from '@microsoft/teams-js';
import MeetingCard from '../common/MeetingCard/MeetingCard';
import Error from '../common/Error/Error';
import { ColourEnergyOrder } from '../../global/types';
// import { getTextLabelColour } from '../../util/theme';
// import useTabSize from '../../util/useTabSize';

interface MeetingSectionProps {
  title: string;
  subtext: string;
  meetings: Array<unknown>;
  theme: string;
  error: number | null;
  onSuccessConsent(): void;
}

/**
 * The Meeting Card template is used to display details
 * about upcoming meetings such as: Meeting name, date/time,
 * invitee count and the most prominent colour energy.
 */
function MeetingSection({
  title = '',
  subtext = '',
  meetings = [],
  theme,
  error,
  onSuccessConsent,
}: MeetingSectionProps) {
  const { t } = useTranslation();
  // const tabSize = useTabSize();

  // if (meetings.length === 0) return null;

  return (
    <div
      id="meetingSection"
      className={`bg_${theme}_secondary border_${
        theme || 'default'
      } summary_section_shadow card_padding mt-3`}
      style={{ paddingBottom: 18 }}
    >
      <div className="flex-column">
        {error ? (
          <Error
            error={error}
            consentScope="Calendars.Read TeamsTab.Read.All User.Read.All People.Read People.Read.All Chat.ReadBasic ChannelMember.Read.All Presence.Read Presence.Read.All Contacts.Read AppCatalog.Read.All TeamsAppInstallation.ReadWriteSelfForChat TeamsTab.ReadWriteForChat"
            // consentScope="Calendars.Read TeamsTab.Read.All User.Read.All People.Read People.Read.All Chat.ReadBasic ChannelMember.Read.All Presence.Read Presence.Read.All Contacts.Read AppCatalog.Read.All TeamsAppInstallation.ReadWriteSelfForChat TeamsTab.ReadWriteForChat TeamsTab.ReadWriteForChat.All"
            successCallback={onSuccessConsent}
            theme={theme}
          />
        ) : (
          <div>
            <div className="d-flex flex-row justify-content-between align-items-start">
              <div className="d-flex flex-column">
                <h3
                  className={`text_colour_${theme} section_title mt-2`}
                  id="meetingSectionTitle"
                >
                  {title}
                </h3>
                {meetings.length === 0 ? (
                  <span className={`text_colour_${theme} default_sub_title`}>
                    {t('ms-teams-meeting-section-no-upcoming-meetings')}
                  </span>
                ) : (
                  <span className={`text_colour_${theme} default_sub_title`}>
                    {subtext}
                  </span>
                )}
              </div>
              {/* Add back once fixed in demo/prod */}
              {/* {tabSize.tabWidth >= 600 ? (
                <button
                  type="button"
                  onClick={() => {
                    if (pages.isSupported()) {
                      pages.navigateToApp({
                        appId: process.env.REACT_APP_TEAMS_APP_ID as string,
                        pageId: process.env
                          .REACT_APP_SETTINGS_ENTITY_ID as string,
                      });
                    }
                  }}
                  className="personal_dashboard_button d-sm-none d-md-block mt-2 hideCustomise"
                  style={{
                    color: getTextLabelColour(theme),
                  }}
                >
                  {t('ms-teams-customise')}
                </button>
              ) : null} */}
            </div>
            <div className="d-flex flex-wrap pt-3" id="meetingSectionContainer">
              {meetings
                && meetings.map(
                  ({
                    title,
                    start,
                    end,
                    attendees,
                    deeplink,
                    meetingId,
                    isOnlineMeeting,
                    collectiveColourEnergyValues,
                  }: any, index) => (
                    <MeetingCard
                      key={meetingId}
                      theme={theme}
                      title={title}
                      start={start?.dateTime}
                      end={end?.dateTime}
                      attendees={attendees.length}
                      collectiveColourEnergyValues={
                        collectiveColourEnergyValues
                      }
                      colourEnergyOrder={Object.keys(
                        collectiveColourEnergyValues,
                      ).sort(
                        (prevKey, curKey) => collectiveColourEnergyValues[curKey]
                          - collectiveColourEnergyValues[prevKey],
                      ) as ColourEnergyOrder}
                      deeplink={deeplink}
                      meetingId={meetingId}
                      isOnlineMeeting={isOnlineMeeting}
                      id={`meetingCard${index}`}
                    />
                  ),
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MeetingSection;
